.ShipmentDetailCommon() {
  .ShipmentSummaries {
    .SegmentedControl-Segment {
      @media (max-width: @screen-xs-max) {
        font-size: @font-size-sm-plus;
      }
    }
    &,
    .StickyElement-Inner {
      .SimpleCard {
        .border-radius(@borderRadiusSm, top);
      }
    }
    .ShipmentSummaries-JobNumberRow {
      padding-left: @spaceNorm;
      padding-right: @spaceMd;
      border-bottom-width: 0;
    }
    .LabelWithValue .label.size-full,
    div.CommonLabelValue-Label {
      font-size: @font-size-xs;
      font-weight: 500;
    }
    .ShipmentSummaries-AssetsCol {
      text-align: center;
      padding-left: @spaceXs;
      width: 58px;
    }
  }
  .ShipmentDetail-LeftColumn {
    width: 370px;
  }
  .SlidingContent {
    .SimpleCard,
    .SimpleCard-Body {
      border-radius: 0;
    }
    .SimpleCard:last-child {
      &,
      .SimpleCard-Body {
        .border-radius(@borderRadiusSm, bottom);
      }
    }
  }

  .DocumentsTable,
  .TrackingHistoryTable {
    .SimpleTable-Body {
      .SimpleTable-Row {
        height: 40px;
        border: 1px solid @colorGrey9_8;
      }

      .SimpleTable-Cell {
        font-size: @font-size-base;
        font-weight: 500;
        border-width: 0;
        background-color: @colorWhite;
      }
    }
  }

  .DocumentsTable {
    .SimpleTable-Body {
      .SimpleTable-Cell {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: @colorGrey3;
        padding: 0;
      }
      .SimpleTable-Row {
        > .SimpleTable-Cell {
          &:nth-child(1) {
            width: 90px;
          }
          &:nth-child(2) {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .InlineLink {
      color: @colorBlue3;

      > span {
        text-decoration: underline;
      }

      @{fa-selector} {
        text-decoration: none;
      }
    }
  }

  .DocumentsUploadButtonWrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 85px;
  }

  .ant-btn@{btn-default-selector}.DocumentsUploadButton {
    width: 100%;
    color: @colorRed;

    .def-btn-disabled({
      color: rgba(@colorGrey3_5, 0.5);
    });
  }

  .TrackingHistoryTable {
    .SimpleTable-Body {
      .SimpleTable-Cell {
        color: @colorGrey3;
      }
    }
    .SimpleTable-Head {
      height: 24px;
      background-color: rgba(@colorBlue4, 0.5);
      color: @colorWhite;
      font-size: @font-size-sm;
      font-weight: 500;
    }

    .SimpleTable-Body {
      .SimpleTable-Row {
        > .SimpleTable-Cell {
          &:nth-child(1) {
            width: 70px;
          }
          &:nth-child(2) {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .SimpleCard.ShipmentMilestonesCard {
    > .SimpleCard-Header {
      position: relative;
      .safari-safe-z-index(1);
    }
    > .SimpleCard-Body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 @spaceNorm2;
      position: relative;
      padding-top: @spaceNorm;
    }
  }
}

.ShipmentDetailExternalLink {
  position: absolute;
  top: -5px;
  right: 16px; // To be aligned with assets inventory icon
  color: @colorRed;
}

.ShipmentDetail-DeviceAddonRow {
  &-Header {
    position: relative;
    display: flex;
    align-items: center;
    height: 22px;
    background-color: @colorWhite6;
    border: 1px solid @colorGrey9_5;
    padding: 0 @spaceNorm;

    &.active {
      background-color: @colorRed;
      color: @colorWhite;
      .IconButton {
        color: @colorWhite;
      }
      .ShipmentDetail-DeviceAddonRow-Index {
        background-color: @colorWhite;
        color: @colorRed;
      }
    }
  }
  &-Index {
    .number-in-circle(@size: 18px, @font-size: @font-size-sm-plus, @background-color: @colorRed);
    margin-right: @spaceMd;
  }
  &-HeaderLabel {
    font-size: @font-size-xs;
    text-transform: uppercase;
    margin-right: @spaceXs;
  }
  &-HeaderValue {
    font-family: @fontCondensed;
    font-size: @font-size-sm;
    font-weight: bold;
  }
  .IconButton {
    color: @colorRed;
    position: absolute;
    top: 50%;
    right: 11px; // To be aligned with assets inventory icon
    transform: translateY(-50%);
  }
  &-Body {
    .LabelRow {
      padding: @spaceNorm 0;
    }
  }
  &:last-child .LabelRow {
    padding-bottom: 0;
  }
}
.ShipmentSummaryCard-Section {
  overflow: hidden;
  padding: 0 @spaceNorm;

  .LabelWithValue {
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      > .label {
        width: 36%;
      }
    }
  }
}
.ShipmentSummaryCard-SectionBody {
  padding: 0 @spaceNorm;
}
.ShipmentSummaryCard-SectionHeader {
  height: 25px;
  line-height: 24px;
  background-color: @colorGrey9_8;
  padding-left: 10px;
  border-top: 1px solid @colorGrey7_8;
  font-size: @font-size-sm-plus;
  font-weight: bold;
  margin-top: @spaceNorm;
}

.ShipmentDetail__BlindedBlock(@fontSize: @font-size-sm-plus) {
  font-weight: 500;
  font-style: italic;
  color: @colorGreyBluish;
  font-size: @fontSize;
  display: flex;
  align-items: center;
}

.ShipmentDetail__DateLabel {
  font-family: @fontCondensed;
  font-weight: bold;
  font-size: @font-size-xs;
  letter-spacing: -0.15px;
  color: @colorBlue3;
}
.ShipmentDetail__DateValue {
  font-family: @fontCondensed;
  font-weight: bold;
  font-size: @font-size-sm-plus;
  letter-spacing: -0.2px;
}

.ShipmentDetail__BlindedAddress {
  background-image: url('../../../resources/img/address-placeholder.png');
  width: 161px;
  height: 112px;
  padding-left: @spaceXs;
  padding-right: @spaceNorm2;
  padding-bottom: @spaceMd;
  .ShipmentDetail__BlindedBlock();
}

.ShipmentDetail__BlindedFlights {
  background-image: url('../../../resources/img/flights-placeholder.png');
  width: 350px;
  height: 142px;
  padding-bottom: @spaceMd;
  justify-content: center;
  .ShipmentDetail__BlindedBlock();
}

.ShipmentDetail__BlindedActivity {
  padding: @spaceMd 0;
  justify-content: center;
  .ShipmentDetail__BlindedBlock();
}

.ShipmentDetail__BlindedAccountDetails {
  padding: @spaceXs 0;
  justify-content: center;
  .ShipmentDetail__BlindedBlock();
}

.ShipmentDetail__BlindedMap {
  .absolute(0);
  background-color: rgba(@colorWhite, 0.35);
  backdrop-filter: blur(2px);
  .ShipmentDetail__BlindedBlock(@font-size-lg);
  justify-content: center;

  > span {
    display: inline-block;
    width: 260px;
    text-align: center;
  }
}

.SimpleCard.ServiceUpdateCard {
  margin: @spaceSm 0 @spaceNorm 0;

  .SimpleCard-Header {
    height: 40px;
    background-color: @colorOrange3;

    h4.ant-typography {
      color: @colorWhite;
      line-height: 16px;
      margin-top: 4px;
      > .ServiceUpdateCard-Subtitle {
        font-size: @font-size-sm;
        text-transform: none;
      }
    }
  }

  .ServiceUpdateCard-GroupTitle {
    font-size: @font-size-base;
    font-weight: 700;
    padding-top: @spaceNorm;
    color: @colorOrange3;
  }

  .ServiceUpdateCard-Reason {
    font-weight: 500;
  }
}

.AirlineFlightStatusCard {
  .AirlineFlightStatusCard-Section,
  .AirlineFlightStatusCard-Header {
    border-bottom: 2px solid @colorGrey9_5;
    &:last-child {
      border-bottom-width: 0;
    }
  }
  .AirlineFlightStatusCard-Section {
    padding: @spaceNorm;
  }
  .AirlineFlightStatusCard-Header {
    padding: @spaceNorm0_5 @spaceSm;
  }
  .AirlineFlightStatusCard-Index {
    .flex-center();
    margin-top: 7px;
    border-radius: 100%;
    width: 18px;
    height: 18px;
    border: 1px solid @colorGreyBluish7;
    background-image: linear-gradient(
      180deg,
      @colorGreyBluish8,
      @colorGreyBluish7
    );
    font-family: @fontCondensed;
    font-size: @font-size-lg;
    font-weight: bold;
    color: @colorWhite;
  }
  .CommonLabelValue-Text,
  .CommonLabelValue-Label {
    line-height: 16px;
  }
  .CommonLabelValue-Text {
    font-weight: 500;
  }
  .CommonLabelValue-Label {
    font-size: @font-size-sm-plus;
  }
  .AirlineFlightStatusCard-MediumFont .CommonLabelValue-Label {
    font-size: @font-size-sm-minus;
  }
  .AirlineFlightStatusCard-MediumFont .CommonLabelValue-Text {
    font-size: @font-size-base;
  }
  .AirlineFlightStatusCard-SmallFont .CommonLabelValue-Label {
    font-size: @font-size-sm-minus;
  }
  .AirlineFlightStatusCard-SmallFont .CommonLabelValue-Text {
    font-size: @font-size-sm-plus;
  }
  .AirlineFlightStatusCard-LargeFont .CommonLabelValue-Label {
    font-weight: 500;
  }
  .AirlineFlightStatusCard-LargeFont .CommonLabelValue-Text {
    font-size: @font-size-sm-plus;
    font-weight: bold;
  }
  .indent-left {
    margin-left: @spaceXs;
  }
}

#ShipmentDetailPage {
  .ShipmentDetailCommon();
}

#ConfirmOrderPage,
#ShipmentDetailPage {
  .ConfirmOrderPage-Quote,
  .SimpleCard.ShipmentMilestonesCard > .SimpleCard-Body {
    height: 128px;
    .MilestoneStepper-Descriptions {
      // There are always two lines in this use-case and correct height is needed for centering to work
      height: 2.6em;
    }
  }
}

#ConfirmOrderPage {
  .ShipmentDetailCommon();

  .ModalPage-InnerContent {
    @media (min-width: @screen-lg-min) {
      > * {
        .space-between(@spaceNorm2, vertical);
      }
    }
  }

  .ConfirmOrderPage-Buttons {
    > .ant-col {
      .space-between(@spaceXxs, horizontal);
    }

    .ant-btn {
      border-radius: 0;
      padding: 0;
    }
    .ant-btn.ant-btn--round-left {
      .border-radius(@borderRadiusSm, left);
    }
    .ant-btn.ant-btn--round-right {
      .border-radius(@borderRadiusSm, right);
    }
    .ant-btn.ant-btn__font-plus-1 {
      @media (min-width: @screen-lg-min) {
        font-size: @heading-1-size;
      }
      @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
        font-size: @heading-3-size;
      }
      @media (max-width: @screen-xs-max) {
        font-size: @heading-4-size;
      }
      &.ant-btn-loading {
        &,
        &:active,
        &:focus,
        &[disabled] {
          @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
            font-size: @heading-4-size;
          }
          @media (max-width: @screen-xs-max) {
            font-size: @font-size-lg;
          }
        }
      }
    }
    .ant-btn.ant-btn__font-plus-2 {
      @media (min-width: @screen-lg-min) {
        font-size: @heading-1-size;
      }
      @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
        font-size: @heading-2_5-size;
      }
      @media (max-width: @screen-xs-max) {
        font-size: @heading-3-size;
      }
      &.ant-btn-loading {
        &,
        &:active,
        &:focus,
        &[disabled] {
          @media (max-width: @screen-xs-max) {
            font-size: @heading-3_5-size;
          }
        }
      }
    }

    > .ant-col.ant-col__small {
      @media (min-width: @screen-lg-min) {
        width: 140px;
      }
      @media (max-width: @screen-md-max) {
        width: 66px;
      }
    }
    > .ant-col.ant-col__big {
      .Flex1();
    }

    .ant-btn-lg {
      display: block;
      width: 100%;

      @media (min-width: @screen-lg-min) {
        height: 80px;
      }
      @media (max-width: @screen-md-max) {
        height: 60px;
      }

      &.ant-btn-primary {
        box-shadow: 0 1px 2px @colorGreyBluish5,
          6px 6px 30px rgba(@colorGreyBluish, 0.5);
        font-weight: bold;
        border-width: 0;

        &.ant-btn-loading {
          &,
          &:active,
          &:focus,
          &[disabled] {
            background-color: @colorOrange3;
            border-width: 0;
            color: @colorWhite;
          }
        }
      }

      &@{btn-default-selector} {
        border-width: 2px;
        box-shadow: 2px 0 0 @colorWhite2;

        @media (min-width: @screen-lg-min) {
          font-size: @heading-3_5-size;
        }
        @media (max-width: @screen-md-max) {
          font-size: @heading-4-size;
        }
      }
    }
  }

  .ConfirmOrderPage-Quote {
    .flex-center();
    flex-direction: column;

    .ConfirmOrderPage-QuoteLabel {
      color: @colorGreyBluish2;
      font-weight: 500;
      text-transform: uppercase;
      &.size-sm {
        font-size: @font-size-sm-plus;
      }
      &.size-lg {
        font-size: @heading-3-size;
      }
    }
    .ConfirmOrderPage-QuoteValue {
      color: @colorRed;
      font-weight: 500;
      line-height: 1;
      &.size-sm {
        font-size: @heading-4-size;
      }
      &.size-md {
        font-size: @heading-3_5-size;
      }
      &.size-lg {
        font-size: 30px;
        margin-bottom: @spaceMd;
      }
    }
  }

  .ConfirmOrderPage-Main {
    margin-bottom: @spaceNorm;
  }
}

.ShipmentSensorWidgets {
  padding-top: @spaceNorm;

  > .ant-row {
    margin-bottom: -@spaceNorm2;

    > .ant-col {
      margin-bottom: @spaceNorm2;
    }
  }
}

.SensorDataCard {
  position: relative;
  height: 188px;
  .flex-center();

  .Gauge-Container {
    margin-bottom: 12px;
  }

  &-LastReport {
    position: absolute;
    bottom: @spaceSm;
    left: 0;
    right: 0;
    text-align: center;
    font-size: @font-size-xs;
    line-height: 1;
    font-weight: 500;
    font-family: @font4;
  }

  .BatterySensorContent-Container {
    align-self: stretch;
    padding-top: @spaceNorm3;
  }

  .BatterySensorContent-Value {
    .flex-center();
    margin-bottom: @spaceMd;
    font-weight: 900;
    font-size: @heading-3-size;
    line-height: 28px;
    color: @colorGrey5;
  }
  .BatterySensorContent-Low .BatterySensorContent-Value {
    color: @colorRed2;
  }

  .BatterySensorContent-BatteryContainer {
    .flex-center();
    .BatterySensorContent-BatteryOutline {
      width: 114px;
      height: 50px;
      border-radius: 6px;
      border: 4px solid @colorGrey6;
      padding: 3px 4px;
    }
    .BatterySensorContent-BatteryRightPart {
      width: 4px;
      height: 28px;
      border-radius: 4px;
      margin-left: -3px;
      border: 4px solid @colorGrey6;
    }
    .BatterySensorContent-BatteryState {
      display: flex;
      position: relative;
      height: 100%;
      .BatterySensorContent-BatteryPowerPart {
        position: relative;
        flex: 1;
        .space-between(5px, horizontal);

        &::before {
          content: '';
          .absolute(0);
          border-radius: 2px;
          border: 1px dashed @colorGrey6;
        }

        .BatterySensorContent-BatteryPowerPartInner {
          .absolute(0);
          right: initial;
        }
      }
    }
  }
  .BatterySensorContent-Low .BatterySensorContent-BatteryPowerPartInner {
    background-color: @colorRed2;
  }
  .BatterySensorContent-Medium .BatterySensorContent-BatteryPowerPartInner {
    background-color: @colorOrange3;
  }
  .BatterySensorContent-High .BatterySensorContent-BatteryPowerPartInner {
    background-color: @colorLightGreen2;
  }

  .LightDetectionSensorContent-Title {
    position: absolute;
    top: 10px;
    font-size: @font-size-lg;
    line-height: 19px;
    &.on {
      font-weight: 500;
      color: @colorRed2;
    }
    &.off {
      font-weight: normal;
    }
  }
  .LightDetectionSensorContent-Img {
    &.on {
      background-image: url('../../../resources/img/icons/lightbulb-on.png');
      width: 101px;
      height: 101px;
    }
    &.off {
      margin-top: @spaceNorm;
      background-image: url('../../../resources/img/icons/lightbulb-off.png');
      width: 54px;
      height: 85px;
    }
  }
  .TemperatureSensorContent-MinValue,
  .TemperatureSensorContent-MaxValue {
    position: absolute;
    top: @spaceXs;
    font-family: @font4;
    font-weight: 500;
    font-size: @font-size-sm-minus;

    strong {
      font-size: @font-size-sm;
      width: 58px;
      display: inline-block;
      vertical-align: middle;
    }
    @{fa-selector} {
      font-size: 16px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .TemperatureSensorContent-MinValue {
    left: @spaceNorm;
  }
  .TemperatureSensorContent-MaxValue {
    right: @spaceNorm;
    text-align: right;
  }
}

.ShipmentMilestonesSteps {
  .MilestoneStepper-Titles *:not(:first-child):not(:last-child) {
    color: @colorGreyBluish;
  }
}

.ShipmentHeaderButton.IconButton {
  color: @colorRed;
  text-transform: uppercase;
  font-family: @fontStd;
  font-weight: 500;

  @{fa-selector} {
    font-size: 18px;
    margin-bottom: @spaceXxs;
  }
}

.ShipmentSummary-DeliveryProof {
  font-size: @font-size-sm-plus;
  font-weight: normal;
  font-style: italic;
  color: @colorBlue2;
  text-transform: uppercase;
  margin-top: @spaceSm;
  margin-bottom: @spaceNorm0_5 - @spaceSm;
}

.ShipmentSummary-DeliveredStatus {
  .text {
    color: @colorBlue2;
  }
}

.ShipmentAssetsInventory {
  padding: @spaceNorm;
}

.ShipmentAssetsInventory-Entry {
  .list-card();
  position: relative;

  .responsive-md-vs-lg(
    @md-and-smaller: {
      padding: @spaceSm @spaceNorm;
    },
    @lg-and-bigger: {
      padding: @spaceSm @spaceNorm2;
    }
  );

  &:hover {
    .list-card-hover();
  }
  &:active {
    .list-card-active();
  }

  .LabelWithValue {
    .responsive-md-vs-lg(
      @md-and-smaller: {
        .space-between(@spaceSm, vertical);
      },
      @lg-and-bigger: {
        .space-between(@spaceXxs, vertical);
      }
    );

    .label {
      margin-bottom: @spaceXxs;
    }
    .text {
      font-size: @font-size-sm-plus;
    }
  }

  .responsive-md-vs-lg(
    @md-and-smaller: {
      height: 120px;

      .col-1 {
        width: 100%;
        margin-bottom: @spaceSm;
      }
      .cols-2-3 {
        width: 100%;
        > .ant-row {
          .space-between(@spaceSm, vertical);
        }
      }
      .col-2 {
        width: 60%;
      }
      .col-3 {
        width: 40%;
      }
    },
    @lg-and-bigger: {
      height: 80px;
  
      .col-1 {
        width: 200px;
      }
      .cols-2-3 {
        .Flex1();
        > .ant-row {
          .space-between(@spaceSm, vertical);
        }
      }
      .col-2 {
        width: 220px;
      }
      .col-3 {
        .Flex1();
      }
    }
  );

  > .ant-row {
    width: 100%;
  }
}

.ShipmentPackagePieceList-Button {
  position: absolute;
  top: 50%;
  right: 24px; // To be aligned with assets inventory icon
  margin-top: -9px;
  font-size: @font-size-xs-minus;
  font-weight: 500;
  text-transform: uppercase;
  color: @colorGreyBluish;
  margin-right: @spaceSm;

  > @{fa-selector} {
    font-size: @font-size-lg;
    color: @colorRed;
  }
}

.ShipmentPackagePieceList {
  @media (min-width: @screen-lg-min) {
    padding: @spaceNorm 0;
  }
  @media (max-width: @screen-md-max) {
    padding: @spaceNorm 0;
  }

  .TwoLinesSeparator {
    @media (max-width: @screen-md-max) {
      &:first-child {
        display: none;
      }
    }
  }

  .ShipmentPackagePieceList-Row {
    display: flex;
    align-items: center;

    > .ant-row {
      margin-bottom: -@spaceNorm;
      > .ant-col {
        margin-bottom: @spaceNorm;
      }
    }

    @media (min-width: @screen-lg-min) {
      padding: @spaceNorm;
    }
    @media (max-width: @screen-md-max) {
      padding: @spaceNorm;
    }

    .number-in-circle {
      margin-right: @spaceNorm2;
    }
  }
}

.DocumentsUploadDialog {
  .DocumentsUploadDialog__Buttons {
    display: flex;
    justify-content: center;

    > .ant-btn {
      width: 100%;
      max-width: 150px;
      @media (min-width: @screen-lg-min) {
        .space-between(@spaceNorm2, horizontal);
      }
      @media (max-width: @screen-md-max) {
        .space-between(@spaceNorm, horizontal);
      }
    }
  }
}
