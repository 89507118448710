// Main card used in new shipment
.NewShipmentCardBody {
  .no-borders() {
    padding: @spaceMd @spaceNorm;
    &.ConfigurableCard-Body {
      padding: 0;
      padding-top: @spaceMd;
      background-color: transparent;
      border-width: 0;
      box-shadow: none;
    }
  }

  .responsive-sm-vs-md(
    @sm-and-smaller: {
      padding: @spaceMd @spaceNorm;
    },
    @md-and-bigger: {
      padding: @spaceNorm1_5 @spaceNorm2;
    }
  );

  @media (max-width: @screen-xs-max) {
    #NewShipmentPage & {
      .no-borders();
    }
  }
}

// Card used for job locations
.JobLocationCardBody {
  .NewShipmentCardBody();
}

.JobLocationCard-Section {
  padding-top: @spaceNorm;

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    .JobLocationCard-SectionBody {
      padding-bottom: 0;
    }
    .TwoLines {
      display: none;
    }
  }

  .TwoLines {
    .responsive-sm-vs-md(
      @sm-and-smaller: {
        margin-left: -@spaceNorm;
        margin-right: -@spaceNorm;
      },
      @md-and-bigger: {
        margin-left: 0;
        margin-right: 0;
      }
    );
  }

  .JobLocationCard-SectionHeader {
    .responsive-md-vs-lg(
      @md-and-smaller: {
        margin-bottom: @spaceNorm;
      },
      @lg-and-bigger: {
        margin-bottom: @spaceNorm0_5;
      }
    );
  }
  .JobLocationCard-SectionTitle {
    text-transform: uppercase;
    font-family: @fontCondensed;
    font-size: @font-size-lg-minus;
    font-weight: bold;
    color: @colorGreyBluish3;
    line-height: 1;

    & + * {
      color: @colorRed;
      font-size: @font-size-sm-plus;
      font-weight: 500;

      @{fa-selector} {
        font-size: @font-size-lg-minus;
      }
    }
  }
  .JobLocationCard-SectionBody {
    padding-bottom: @spaceNorm1_5;

    &.size-tiny {
      margin-top: -@spaceSm;
      padding-bottom: @spaceNorm0_5;
    }

    > *,
    > .ant-row.ant-form-item {
      .space-between(@spaceNorm, vertical);

      > * {
        margin-bottom: 0;
      }
    }
    > .ant-row {
      margin-bottom: 0;
      &:last-child {
        margin-bottom: -@spaceNorm;
      }

      > * {
        margin-bottom: @spaceNorm;
      }
    }
  }
}

// Card at the top with shipment templates controls
.ShipmentTemplatesCard {
  .ConfigurableCard-Body {
    padding: @spaceMd / 2 @spaceNorm;
  }

  .ant-btn {
    width: 130px;

    @{fa-selector} {
      color: @colorRed;
    }
    .def-btn-disabled({
      @{fa-selector} {
        color: inherit;
      }
    });
    span {
      line-height: 1;
    }
  }
}
