.MilestoneStepper {
  @circle-size: 30px;
  @outer-circle-size: 40px;
  @box-shadow: 0 4px 6px rgba(@colorGrey8_7, 0.65);
  @border-color: @colorGrey7_7;
  @background-color: @colorGrey9_9;

  &-container {
    margin: 0 @outer-circle-size / 2;
  }

  &-TimelineSection {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-Titles {
    line-height: 1.2em;
    white-space: nowrap;
    font-family: @fontStd;
    font-size: @font-size-base;
    font-weight: 500;
    height: 18px;
    color: @colorGrey3;
  }

  &-Subtitles {
    height: 1.2em;
    line-height: 1.2em;
    white-space: nowrap;
    font-size: @font-size-sm-plus;
    font-weight: normal;
    color: @colorGreyBluish;
  }

  &-Descriptions {
    height: 1.3em;
    line-height: 1.3em;
    white-space: nowrap;
    font-size: @font-size-sm;
    color: @colorGreyBluish;
  }

  &-AlignedTexts {
    position: relative;
  }
  &-Position {
    position: absolute;
  }
  &-Alignment {
    transform: translate(-50%);
    text-align: center;
  }
  &-Position:first-child &-Alignment {
    transform: translate(-(@circle-size / 2));
    text-align: left;
  }
  &-Position:last-child &-Alignment {
    transform: translate(calc(-100% + @circle-size / 2));
    text-align: right;
  }

  &-Timeline::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background-color: @background-color;
    .safari-safe-z-index(100);
  }
  &-Timeline {
    .safari-safe-z-index(1);
    position: relative;
    width: 100%;
    height: 18px;
    background-color: @background-color;
    color: @colorWhite;
    border: 1px solid @border-color;
    box-shadow: @box-shadow;
    .flex-center;
  }
  &-Step {
    position: absolute;
    top: 50%;
  }
  &-CircleStep {
    .flex-center;
    &-outer {
      position: absolute;
      width: @outer-circle-size;
      height: @outer-circle-size;
      background-color: @background-color;
      border-radius: 100%;
      border: 1px solid @border-color;
      box-shadow: @box-shadow;
      .safari-safe-z-index(0);
    }
    &-inner {
      position: absolute;
      .flex-center;
      width: @circle-size;
      height: @circle-size;
      background-image: linear-gradient(
        180deg,
        @colorGrey8 0%,
        @colorGrey4 100%
      );
      border: 1px solid @colorGrey4;
      border-radius: 100%;
      font-size: 16px;
      color: @colorWhite;
      .safari-safe-z-index(200);
    }
    &-active > &-inner {
      background-image: linear-gradient(180deg, @colorRed3 0%, @colorRed4 100%);
      border: 1px solid @colorRed5;
    }
  }
  &-RectStep {
    .flex-center;
    &-inner {
      position: absolute;
      width: 10px;
      height: 14px;
      background-color: @colorGrey3;
      border-radius: 4px;
      .safari-safe-z-index(200);
    }
    &-active > &-inner {
      height: 16px;
      width: 12px;
      border: 2px solid @colorRed;
      background-color: @colorWhite;
    }
    &-deactivated > &-inner {
      height: 16px;
      width: 12px;
      border: 2px solid @colorWhite;
    }
  }
  &-ProgressBar {
    background-color: @colorRed;
    border-top: 1px solid @colorRed5;
    border-bottom: 1px solid @colorRed5;
    position: absolute;
    height: 10px;
    left: 0;
    .safari-safe-z-index(110);
  }
}
