.NewShipment-ServiceInformation-TopCol {
  .responsive-md-vs-lg(
    @md-and-smaller: {
      width: 100%;
    },
    @lg-and-bigger: {
      width: 50%;
    }
  );
}

.NewShipment-ServiceInformationCard {
  padding-top: @spaceNorm2;
  padding-bottom: @spaceNorm;

  .DangerousGoodsIcon {
    font-size: 18px;
    color: @colorRed;
    padding-right: 15px;
  }
}

.NewShipment-ServiceInformation-SectionDivider {
  margin-top: @spaceNorm;

  @media (min-width: @screen-lg-min) {
    margin-left: -@spaceNorm2;
    margin-right: -@spaceNorm2;
  }
  @media (max-width: @screen-md-max) {
    margin-left: -@spaceNorm;
    margin-right: -@spaceNorm;
  }

  &Title {
    font-weight: bold;
    margin-bottom: @spaceNorm0_5;
    white-space: nowrap;

    .responsive-md-vs-lg(
      @md-and-smaller: {
        height: 20px;
        line-height: 20px;
        padding-left: @spaceNorm;
        font-family: @fontCondensed;
        font-size: @font-size-base;
        margin-top: @spaceSm;
        border-width: 0;
        box-shadow: none;
        text-transform: initial;
      },
      @lg-and-bigger: {
        height: 30px;
        line-height: 30px;
        background-color: @colorWhite6;
        padding-left: @spaceNorm2;
        font-size: @font-size-base;
        border-bottom: 1px solid @colorWhite;
        box-shadow: 0 1px 0 @colorWhite;
        margin-top: 0;
        text-transform: uppercase;
      }
    );
  }
}

.NewShipment-DGStepperRow {
  margin-bottom: -@spaceNorm;

  > .ant-col {
    padding-bottom: @spaceNorm;
  }

  > .col-1 {
    display: flex;
    .responsive-sm-vs-md(
      @sm-and-smaller: {
        width: 50%;
      },
      @md-and-bigger: {
        width: auto;
      }
    );

    .ant-form-item {
      flex-grow: 1;
      padding-right: 20px;
    }
  }
  > .col-2 {
    display: flex;
    padding-left: 10px;
    justify-content: flex-start;
    .responsive-sm-vs-md(
      @sm-and-smaller: {
        width: 50%;
      },
      @md-and-bigger: {
        width: auto;
      }
    );
    .ant-form-item {
      flex-grow: 1;
    }
  }

  .label {
    padding-left: 10px;
    font-size: 12px;
    color: @colorGreyBluish;
  }
}
.NewShipment-DGYesNo {
  > *,
  .ant-radio-wrapper {
    line-height: @input-height-md / 2;
  }
  .ant-form-item-control-input-content,
  .ant-radio-group {
    line-height: 0;
  }
}
.NewShipment-UNDescription {
  .label {
    line-height: 17px;
  }
  .text {
    font-size: @font-size-lg-minus;
    font-weight: 500;
    line-height: 18px;
  }
}
.NewShipment-AddEmailNotifications {
  color: @colorRed2;
  font-weight: 500;
  // Firefox renders in a buggy way if a parent has underline and a child contains text
  > span:last-child {
    text-decoration: underline;
  }
}
