@ConfigurableCard-footerSideMargin: @spaceXs;

.ConfigurableCard {
  &-Header {
    height: 24px;
    background-color: @colorGreyBluish;
    padding: 0 @spaceNorm;
    box-shadow: -6px -6px 15px @colorWhite;
    .border-radius(@side: top);
    font-weight: 500;
    color: @colorWhite;

    h4.ant-typography {
      font-size: @font-size-base;
      color: @colorWhite;
      margin-bottom: 0;
    }
    .LinksRow {
      font-size: @font-size-sm + 1;
    }
  }
  &-Body {
    position: relative;
    background-color: @colorWhite3;
    border-left: 1px solid @colorGrey9;
    border-right: 1px solid @colorGrey9;
    box-shadow: 1px -1px 15px rgba(@colorGreyBluish, 0.5);
    flex-wrap: initial;

    &.no-footer {
      .border-radius(@side: bottom);
      border-bottom: 1px solid @colorGrey9;
    }
  }
  &-Footer {
    background-color: @colorGrey9_9;
    border: 1px solid @colorWhite;
    border-top-width: 0;
    .border-radius(@side: bottom);
    box-shadow: 0px 2px 6px rgba(@colorGreyBluish, 0.35),
      inset 0 -1px 8px @colorGrey9_7;
    margin: 0 @ConfigurableCard-footerSideMargin 0
      @ConfigurableCard-footerSideMargin;
  }
}

.SimpleCard {
  &-Header {
    position: relative;
    height: 32px;
    text-align: center;
    border-bottom: 1px solid rgba(@colorGreyBluish, 0.35);

    h4.ant-typography {
      font-family: @fontCondensed;
      font-size: @font-size-lg;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  &.collapsible &-Header {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: initial;
    padding: 0 @spaceNorm;
  }

  &-Body {
    background-color: @colorWhite;
    .border-radius(@borderRadiusSm, bottom);
    box-shadow: 1px 1px 6px @colorGrey8_1;
    color: @colorGrey3;
  }

  &.SimpleCardTheme-ROUND_BORDERED {
    border-radius: @borderRadiusSm;
    box-shadow: 0 0 6px @colorGrey8_1;
    background-color: @colorWhite;

    .SimpleCard-Header {
      background-color: @colorWhite7;
      border: 1px solid @colorWhite;
      box-shadow: 0 4px 10px rgba(@colorGreyBluish7_2, 0.25);
      .border-radius(@borderRadiusSm, top);

      h4.ant-typography {
        line-height: 30px;
        color: @colorGrey4_5;
        letter-spacing: -0.18px;
      }
    }

    .SimpleCard-Body {
      font-size: @font-size-sm;
      color: @colorGrey3;
      box-shadow: none;
    }
  }

  &.SimpleCardTheme-FLAT {
    .SimpleCard-Header {
      background-color: @colorGrey10;
      border-top: 2px solid @colorWhite;

      h4.ant-typography {
        line-height: 32px;
        color: @colorGrey3;
        text-transform: uppercase;
        font-size: @font-size-base;
      }
    }

    .SimpleCard-Body {
      font-size: @font-size-sm-plus;
      line-height: @font-size-lg;
      color: @colorGrey3;
    }

    .SimpleCard-Subtitle {
      position: relative;
      height: 24px;
      background-color: @colorWhite3;
      border-top: 1px solid @colorGrey8_1;
      border-bottom: 1px solid @colorWhite;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: @font-size-sm-plus;
      font-weight: bold;

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
      }
      &::before {
        top: 0;
        border-top: 1px solid @colorWhite;
      }
      &::after {
        bottom: 0;
        border-bottom: 1px solid @colorGrey8_1;
      }
    }
  }
}
