.MonitorSearchFilters {
  @sort-icon-size: 30px;

  flex-wrap: initial;

  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    margin-top: @spaceNorm;
    margin-bottom: @spaceNorm;
  }
  @media (max-width: @screen-sm-max) {
    margin-top: @spaceNorm0_5;
    margin-bottom: @spaceNorm0_5;
  }

  &-Column {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    @media (min-width: @screen-lg-min) {
      height: 50px;
      border-right: 1px solid @colorGrey7_8;
      padding: 0 @spaceNorm;
      &:last-child {
        border-right-width: 0;
      }
    }

    @media (min-width: @screen-xl-min) {
      &:first-child {
        width: 32%;
      }
      width: 68% / 3;
    }
    @media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
      &:first-child {
        width: 38%;
      }
      width: 31%;
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      width: 240px;
    }
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      // This small width is to accomodate Filter sets
      width: 190px;
    }
    @media (max-width: @screen-xs-max) {
      width: 230px;
    }
  }
  &-PopupContainer {
    position: relative;

    > .ant-popover-open {
      position: relative;
      z-index: 1;
    }
  }
  &-PopupLabel {
    .flex-center();
    color: @colorGrey3;
    font-weight: bold;
    border-radius: @borderRadiusSm;

    height: 40px;
    @media (max-width: @screen-md-max) {
      height: 30px;
      justify-content: flex-start;

      &.ant-popover-open {
        justify-content: center;
      }
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      padding: 0 @spaceSm;
    }
    @media (max-width: @screen-sm-max) {
      padding: 0;
    }

    .DatePreviewIcon {
      color: @colorGrey3;
    }

    @{fa-selector} {
      margin-left: @spaceSm;
      @media (max-width: @screen-sm-max) {
        margin-left: @spaceXs;
      }
    }

    &.ant-popover-open {
      color: @colorWhite;
      background-color: @colorRed;

      .DatePreviewIcon {
        color: @colorWhite;
      }
    }
  }
  &-Popup {
    width: 100%;

    @media (max-width: @screen-md-max) {
      min-width: 250px;
    }

    .ant-popover-inner-content {
      padding: 0;
    }
    &.DateRangeFilter .ant-popover-inner-content {
      padding: @spaceNorm0_5 @spaceNorm1_5 @spaceNorm @spaceNorm1_5;
    }
  }
  &-SortIcon {
    .absolute(0);
    left: initial;
    width: 30px;
    .flex-center();

    & {
      color: @colorGrey3;
      &:hover {
        color: @colorRed6;
      }

      .IconButton-Icon {
        font-size: 12px;
        transform: rotateZ(90deg);
      }
    }

    &.active {
      color: @colorRed;
      box-shadow: inset 0 3px 6px @colorGrey7_8;
      border-left: 1px solid @colorGrey7_8;

      .IconButton-Icon {
        font-size: 20px;
        transform: none;
      }
    }

    @media (max-width: @screen-md-max) {
      display: none;
    }
  }
  &-Footer {
    height: 40px;
  }
  &-CurrentState {
    display: flex;
    align-items: center;
    // 9 px is to align with the scrollbar outside
    width: @MonitorLeftColumn - @ConfigurableCard-footerSideMargin + 9px;
    padding-left: @spaceNorm5 - @ConfigurableCard-footerSideMargin;
    font-weight: 500;
    font-style: italic;
    font-size: @font-size-sm-plus;
    color: @colorGrey3;
    line-height: 15px;

    .MonitorSearchFilters-CurrentStateBadge {
      margin-right: @spaceXs;
      padding: @spaceXxs @spaceSm;
      line-height: 1;
      min-width: 24px;
      background-color: @colorGreyBluish;
      border-radius: @borderRadiusMd;
      font-weight: bold;
      color: @colorWhite;
    }
  }
  &-Tags {
    .Flex1();
    display: flex;
    align-items: center;
    height: 30px;
    padding-left: @spaceNorm;
    border-left: 1px solid @colorGrey7_8;

    .ant-tag {
      position: relative;
      display: inline-flex;
      align-items: center;
      height: 21px;
      line-height: 19px;
      max-width: 200px;
      padding: 0 @spaceNorm;
      border-radius: @borderRadiusXs;
      border: 1px solid @colorGreyBluish;
      font-weight: 500;

      background-color: @colorGreyBluish;
      color: @colorWhite;

      > * @{fa-selector} {
        font-size: @font-size-base;
        color: inherit;
        margin-left: -@spaceXxs;
        margin-right: @spaceXs;
      }
      > .text {
        .Flex1();
      }
      > * {
        display: block;
      }
      &,
      > .text {
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.closable {
        padding-right: @spaceNorm3;
      }
      &.changed {
        border: 1px solid @colorRed;
        color: @colorRed;
        background-color: @colorWhite;
      }
      &.deleted {
        border: 1px solid @colorRed;
        color: @colorRed;
        background-color: transparent;
      }

      &.ant-tag-hidden {
        display: none;
      }
      .ant-tag-close-icon {
        .absolute-middle-next-to-right();
        right: @spaceSm;
        font-size: 13px;
        color: inherit;
      }
    }
  }
  &-Buttons {
    .flex-center();
    padding-right: @spaceNorm;

    .InlineLink {
      color: @colorRed;
      font-size: @font-size-sm-minus;
      font-weight: 500;
      margin-right: @spaceNorm1_5;

      // Firefox renders in a buggy way if a parent has underline and a child contains text
      > * {
        text-decoration: underline;
      }

      &.disabled {
        color: @colorGrey3_5;
        opacity: 0.25;
      }
    }
  }
  .SearchCol {
    width: 100%;
  }
  .FreeTextJobSearchIcon {
    font-size: 18px;
    color: @colorRed;
  }
}

.SearchSingleSelect {
  padding: @spaceXs 0;
}

.SearchSingleSelect--Group {
  padding: 0 @spaceNorm1_5;
}

.SearchSingleSelect--Item {
  cursor: pointer;
  padding: @spaceNorm0_5 @spaceNorm1_5;
  line-height: 18px;
  color: @colorGrey3;
  font-weight: 500;
  display: flex;
  align-items: center;

  > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      .Flex1();
    }
  }

  & {
    color: @colorGrey3;
  }
  &.active {
    .menu-item-active();
  }
  &:hover {
    .menu-item-hover();
  }
  &:active {
    .menu-item-pressed();
  }
}

.SearchTextFilterSubmenu {
  position: relative;
  border-radius: @borderRadiusSm;
  overflow: hidden;

  .SearchTextFilterSubmenu-Entering {
    .absolute(0);
    background-color: @colorWhite6;
    padding: @spaceNorm2;
    padding-top: @spaceNorm;

    > * {
      .space-between(@spaceNorm, vertical);
    }

    .InlineLink {
      color: @colorRed;
      font-weight: bold;
    }
  }
}

.MonitorFilterDuplicateNameDialog.ant-modal.SimpleDialog {
  .ant-modal-body {
    padding-bottom: @spaceNorm3;
  }
  .ant-row {
    padding: 0 @spaceNorm2;
  }
}
