.mask(@value) {
  background-color: rgba(@colorWhite, @value / 100);
}

each(75, 80, 85, 90, 95; {
  .Mask-@{value} {
    .mask(@value);
  }
});

.InlineDialog {
  position: relative;
  width: 280px;
  height: 140px;
  border: 1px solid @colorWhite;
  background-color: @colorWhite2;
  box-shadow: 0 0 35px @colorGrey6;
  border-radius: @borderRadiusMd;
  padding: @spaceNorm2_5 @spaceNorm @spaceNorm @spaceNorm;

  .InlineDialog-Icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;

    > * {
      display: block;
      font-size: 36px;

      &.fa-exclamation-triangle {
        color: @colorOrange3;

        &::after {
          z-index: -1;
          content: '';
          position: absolute;
          top: 6px;
          bottom: 2px;
          right: 10px;
          left: 10px;
          background-color: @colorWhite;
          border-radius: 100%;
        }
      }
    }
  }

  h2.ant-typography {
    font-weight: 300;
    margin-bottom: @spaceMd;
  }

  div.ant-typography {
    line-height: 19px;
    margin-bottom: 0;
  }
}

.ant-modal.SimpleDialog {
  &.SimpleDialog--TransformCenter {
    transform-origin: center !important;
  }

  @media (max-width: @screen-xs-max) {
    margin: 0;
    max-width: 100vw;
  }

  .ant-modal-content {
    min-height: 220px;
    border: 1px solid @colorWhite;
    background-color: @colorWhite2;
    box-shadow: 0 0 35px rgba(@colorGrey6, 0.35);
    border-radius: @borderRadiusMd;

    @media (min-width: @screen-md-min) {
      width: 360px;
    }
    @media (max-width: @screen-sm-max) {
      width: 280px;
    }
  }

  .ant-modal-body {
    padding: @spaceXl @spaceNorm;
    .FlexCol();
    align-items: center;
  }

  .SimpleDialog-Icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;

    &::before {
      z-index: -1;
      content: '';
      .absolute(2px);
      background-color: @colorWhite;
      border-radius: 100%;
    }

    > * {
      display: block;
      color: @colorRed;
      font-size: 60px;

      &.size-md {
        font-size: 40px;
      }
      &.size-sm {
        font-size: 36px;
      }
    }
  }
  h1.ant-typography {
    font-weight: 300;
    line-height: 1.25;
    margin-bottom: @spaceNorm1_5;
    text-align: center;

    &.sm {
      font-size: @heading-1_5-size;
    }
    &.margin-md {
      margin-bottom: @spaceNorm2;
    }
    &.margin-lg {
      margin-bottom: @spaceNorm3;
    }
  }
  h2.ant-typography {
    font-weight: 300;
    line-height: 1.25;
    margin-bottom: @spaceNorm;
    text-align: center;
    margin-top: 0;
  }
  h4.ant-typography {
    font-weight: 500;
    line-height: 1.25;
    margin-top: 0;
    margin-bottom: @spaceNorm;

    &.margin-lg {
      margin-bottom: @spaceNorm3;
    }
    @media (max-width: @screen-sm-max) {
      font-size: @font-size-lg;
    }
  }
  .SimpleDialog-Label {
    font-size: @heading-4-size;
    margin-bottom: @spaceMd;
    .responsive-sm-vs-md(
      @sm-and-smaller: {
        font-size: @font-size-lg-minus;
      },
      @md-and-bigger: {
        font-size: @heading-4-size;
      }
    );
  }
  span.ant-typography {
    font-size: @font-size-lg;
    line-height: 19px;
  }
  form {
    width: 100%;
    margin-bottom: @spaceNorm2;
  }
  .InlineLink.cancel-button {
    font-size: @font-size-lg-minus;
    font-weight: bold;
    color: @colorRed;
    line-height: 1;
    align-self: flex-end;

    // Firefox renders in a buggy way if a parent has underline and a child contains text
    > * {
      text-decoration: underline;
    }
  }
  .InlineLink {
    font-weight: 500;
    color: @colorBlue2;

    // Firefox renders in a buggy way if a parent has underline and a child contains text
    > * {
      text-decoration: underline;
    }
  }

  &.size-sm {
    .ant-modal-content {
      width: 280px;
    }
  }
}

.ant-modal.SimpleDialog.SimpleDialog--ThemeDark {
  .ant-modal-content {
    border: 1px solid @colorGreyDark5;
    background-color: @colorGreyDark2;
    box-shadow: 0 0 80px @colorBlack0_7;
    border-radius: @borderRadiusSm;
  }
  .ant-modal-body {
    &,
    .ant-typography {
      color: @colorGrey7_4;
    }
  }
}

.SimpleConfirmDialog.SimpleDialog {
  .SimpleDialog-Icon {
    > * {
      color: @colorOrange3;
    }
    &::before {
      top: 6px;
      bottom: 2px;
      right: 10px;
      left: 10px;
    }
  }

  .ant-modal-content,
  .ant-modal-body {
    min-height: 140px;
  }

  .ant-modal-body {
    padding: @spaceNorm2;
    padding-top: @spaceNorm2_5;
  }

  h1.ant-typography {
    line-height: 1;
    margin-bottom: @spaceNorm;

    .responsive-sm-vs-md(
      @sm-and-smaller: {
        font-size: @heading-2-size;
      },
      @md-and-bigger: {
        font-size: @heading-1_5-size;
      }
    );
  }

  span.ant-typography {
    line-height: 19px;
    text-align: center;
    .responsive-sm-vs-md(
      @sm-and-smaller: {
        font-size: @font-size-base;
      },
      @md-and-bigger: {
        font-size: @font-size-lg;
      }
    );
  }

  .SimpleDialog__Buttons {
    align-self: stretch;
    margin-top: @spaceNorm1_5;
  }
  .SimpleDialog__ButtonsBorder {
    margin-top: @spaceNorm2;
    margin-bottom: @spaceNorm0_5;
    margin-left: -@spaceNorm2;
    margin-right: -@spaceNorm2;
    border-top: 1px solid @colorGreyDark5;
  }

  .ant-btn {
    width: 100%;
    font-weight: 500;

    @media (max-width: @screen-sm-max) {
      height: 28px;
      border-radius: @borderRadiusXs;
    }
  }

  &.size-sm {
    .ant-modal-content,
    .ant-modal-body {
      min-height: 125px;
    }

    h1.ant-typography {
      font-size: @heading-2-size;
      font-weight: 300;
      line-height: 36px;
    }

    span.ant-typography {
      font-size: @font-size-base;
    }

    .ant-btn {
      height: 21px;
      border-radius: @borderRadiusXs;
    }
  }
}

#NewShipmentPage,
.TemplateDrawerForm,
.MonitorSearchFilters {
  &.has-dialog {
    .ant-btn,
    .InlineLink {
      opacity: 0.35;
    }
  }
}

.VersionIncompatibilityDialog {
  .ant-modal-body {
    > .ant-row:last-child {
      > .ant-col {
        .flex-center();

        > .ant-btn {
          width: 190px;
        }
      }
    }
  }
}

.ant-modal.SimpleDialog.DocumentsUploadDialog {
  .ant-modal-content {
    min-height: 230px;

    @media (min-width: @screen-md-min) {
      width: 520px;
    }
    @media (max-width: @screen-sm-max) {
      width: 280px;
    }
  }

  .ant-modal-body {
    display: block;
    @media (min-width: @screen-md-min) {
      padding: @spaceNorm2;
    }
    @media (max-width: @screen-sm-max) {
      padding: @spaceNorm2 @spaceNorm;
    }
  }

  .SimpleDialog-Icon {
    margin-top: -6px;

    &::before {
      top: 11px;
      bottom: 4px;
      right: 13px;
      left: 10px;
      border-radius: 0;
    }
  }
}
