.SegmentedControl {
  width: 100%;
  height: 32px;
  background-color: @colorGrey8_9;
  border-radius: @borderRadiusSm;
  padding: @spaceNorm0_5;

  .StepsWithContent > & {
    width: initial;
  }

  .std() {
    font-family: @fontCondensed;
    font-weight: bold;
    color: @colorGrey3;
  }

  .active() {
    font-family: @fontCondensed;
    font-weight: bold;
    color: @colorWhite;
  }

  &-Inner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;

    &:hover .SegmentedControl-Segment.active {
      .std();
      &:hover {
        .active();
      }
    }
  }

  .segment() {
    flex: 1;
    .flex-center();
    cursor: pointer;
    border-radius: @borderRadiusSm;
    transition-property: left, right;
    transition-duration: 300ms;

    &.disabled {
      cursor: not-allowed;
    }
  }

  &-Segment {
    .segment();
    .std();
    z-index: 2;

    &.active,
    &:hover {
      .active();
    }
  }

  &-ActiveIndicator {
    .segment();
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: @colorRed;
    box-shadow: 4px 4px 5px rgba(@colorBlack, 0.14),
      1px 1px 10px rgba(@colorBlack, 0.14);
  }

  .indicator-position(@number, @suffix) {
    each(range(1, @number), {
      .SegmentedControl-Segment@{suffix}:nth-child(@{value}) ~ .SegmentedControl-ActiveIndicator {
        left: (@value - 1) * (100% / @number);
        right: @value * (100% / @number);
      }
    });
  }

  .number-specific(@number) {
    &.num-items-@{number} {
      .SegmentedControl-Segment,
      .SegmentedControl-ActiveIndicator {
        width: 100% / @number;
      }
      // Hover has higher priority, so it must be later
      .indicator-position(@number, ~'.active');
      .indicator-position(@number, ~':hover');
    }
  }

  // Could be increased, but it has a heavy CSS output, so until it's really
  // used in such a way, there is no reason
  each(range(2, 5), {
    .number-specific(@value);
  });
}
