.AdminConsoleTitle__Container {
  height: 40px;
  border-top: 1px solid @colorGreyDark2_5;
  border-bottom: 1px solid @colorBlack1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 @spaceNorm2;
}

.AdminConsoleTitle {
  &.ant-typography {
    h1&,
    h2&,
    h3&,
    h4& {
      display: flex;
      align-items: center;
      line-height: 1;
      margin-bottom: 0;
      margin-top: 0;
      color: @colorGreyBluish7_4;
    }

    h2& {
      font-family: @font6;
      font-size: @heading-2_5-size;
      font-weight: normal;
      gap: @spaceMd;
    }

    h4& {
      font-weight: bold;
      gap: @spaceSm;
      text-transform: uppercase;

      @{fa-selector} {
        margin-top: -1px;
      }
    }
  }
}

.AdminConsoleSideMenu {
  width: 200px;
}

.AdminConsoleSideMenu__Item {
  display: flex;
  color: @colorGreyBluish3_2;

  &.disabled {
    opacity: 0.5;
    &,
    & > * {
      pointer-events: none;
    }
  }
}

.AdminConsoleSideMenu__Item--Level0 {
  height: 40px;
  font-size: @font-size-lg;
  font-weight: bold;
  font-family: @fontCondensed;

  .active-common(@bgColor) {
    background-color: @bgColor;
    color: @colorGreyBluish7_4;
    border: 1px solid @colorGreyDark3;
    border-left-width: 0;
    .border-radius(@borderRadiusSm, right);
    box-shadow: 0 4px 20px rgba(@colorBlack1, 0.65);
  }

  &:hover {
    .active-common(@colorGreyDark1);
  }

  &.active,
  &:active {
    .active-common(@colorGreyDark1_3);
  }
}

.AdminConsoleSideMenu__Submenu--Level0 {
  padding: @spaceMd 0;
}
.AdminConsoleSideMenu__Submenu--Level1 {
  padding: 0;
}

.AdminConsoleSideMenu__Item--Level1 {
  height: 30px;
  font-size: @font-size-base;
  font-weight: 500;
  font-family: @fontStd;

  &:hover {
    color: @colorGreyBluish7_4;
  }

  &.active,
  &:active {
    color: @colorGreyBluish7_4;
  }

  &.AdminConsoleSideMenu__Item--HasSubmenu {
    color: @colorGreyBluish7_4;
  }
}

.AdminConsoleSideMenu__Item--Level2 {
  height: 30px;
  font-size: @font-size-base;
  font-weight: normal;
  font-family: @fontStd;

  &:hover {
    color: @colorGreyBluish7_4;
  }

  &.active,
  &:active {
    color: @colorGreyBluish7_4;
  }
}

.AdminConsoleSideMenu__Item--Level3 {
  height: 25px;
  font-size: @font-size-sm;
  font-weight: normal;
  font-family: @fontStd;

  &:hover {
    color: @colorGreyBluish7_4;
  }

  &.active,
  &:active {
    color: @colorGreyBluish7_4;
  }
}

.AdminConsoleSideMenu__ItemInner {
  &,
  a&,
  a&:link {
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    color: inherit;

    .AdminConsoleSideMenu__Item--Level0 & {
      padding-left: @spaceNorm2;
    }

    .AdminConsoleSideMenu__Item--Level1 & {
      padding-left: @spaceNorm3;
    }

    .AdminConsoleSideMenu__Item--Level2 & {
      padding-left: @spaceXl;
    }

    .AdminConsoleSideMenu__Item--Level3 & {
      padding-left: @spaceNorm5;
    }
  }
}

.AdminConsoleSection {
  border-radius: @borderRadiusSm;
}

.AdminConsoleSection--Level0 {
  background-color: @colorGreyDark1_3;
  box-shadow: 0 4px 20px rgba(@colorBlack1, 0.65);
}

.AdminConsoleSection--Level1 {
  background-color: @colorGreyDark2_7;
  box-shadow: 0 4px 20px rgba(@colorBlack1, 0.5);
}

.AdminConsoleSection__Header {
  .border-radius(@borderRadiusSm, top);
  background-color: @colorGreyDark3;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
}

.AdminConsoleSection__Header--Level0 {
  height: 40px;
}

.AdminConsoleSection__Header--Level1 {
  height: 30px;
}

.AdminConsoleSection__Header--Table {
  padding: 0 @spaceNorm2;
  color: @colorGreyBluish8;
  font-family: @fontCondensed;
  font-weight: bold;

  > .ant-row {
    flex: 1;
  }
}

.AdminConsoleSection__Header--Title {
  justify-content: space-between;
  padding: 0 @spaceNorm2 0 @spaceMd;

  strong {
    font-family: @fontCondensed;
    color: @colorGreyBluish7_4;
    text-transform: uppercase;

    > @{fa-selector} {
      margin-right: @spaceXs;
    }
  }

  .ant-typography {
    color: @colorOrange1;
    margin: 0;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 0.36px;
  }
}

.AdminConsoleSection__HeaderControls {
  position: absolute;
  display: flex;
  align-items: center;
  gap: @spaceNorm;
  right: @spaceNorm;
  top: 50%;
  transform: translateY(-50%);
  color: @colorGreyBluish7_4;
}

.AdminConsoleSection__Subheader {
  display: flex;
  align-items: center;
  height: 30px;
  background-color: @colorGreyDark5;
  text-transform: uppercase;
  border-top: 1px solid @colorGreyDark1_1;
  border-bottom: 1px solid @colorGrey3_5;
  color: @colorGrey7_1;
}
.AdminConsoleSection__Subheader--SizeSm {
  font-family: @fontCondensed;
  font-size: @font-size-sm-plus;
  font-weight: bold;
  padding-left: @spaceNorm2;
}
.AdminConsoleSection__Subheader--SizeLg {
  font-family: @font6;
  font-size: @heading-4-size;
  font-weight: normal;
  letter-spacing: 0.34px;
  padding-left: @spaceNorm;
}

.AdminConsoleSection__Content {
  padding: @spaceNorm2;
  color: @colorGreyBluish3_2;

  .InlineLink {
    font-weight: 500;
    font-size: @font-size-lg-minus;
    color: rgba(@colorGreyBluish7_4, 0.85);
    text-decoration: underline;

    &.disabled {
      opacity: 0.3;
    }
  }
}

.AdminConsoleSection__Line {
  width: 100%;
  height: 0;
  border-top: 1px solid @colorGreyDark3;
}

.AdminConsole__SimpleSelect {
  .ant-select {
    min-width: 140px;

    .ant-select-selector {
      height: 20px !important;

      .ant-select-selection-item {
        justify-content: flex-end;
        margin-right: @spaceSm;
      }
    }
  }
}

.AdminConsole--WithArrow() {
  position: relative;

  &::after {
    .fa-like('\f0d7');
    pointer-events: none;
    .selectArrow();
  }
}

.AdminConsole__ButtonDropdownTrigger.ant-btn {
  .AdminConsole--WithArrow();
  &.disabled {
    padding-right: 0;
    &::after {
      display: none;
    }
  }

  padding-right: 32px + @spaceSm;

  &.AdminConsole__ButtonDropdownTrigger--Borderless {
    border-width: 0 !important;
    background-color: transparent !important;
  }
  &.AdminConsole__ButtonDropdownTrigger--Inline {
    height: auto;
    width: auto;
  }
}

.AdminConsole__InlineLabel {
  font-size: @font-size-lg;
  font-weight: 500;
  color: @colorGreyBluish3_2;
}

.AdminConsoleSection__Table__Headers {
  display: flex;
  gap: @spaceNorm2;
  padding: 0 @spaceNorm2;
  align-items: center;
  height: 30px;
  font-family: @fontCondensed;
  font-size: @font-size-sm-plus;
  color: @colorGreyBluish8;
  border-bottom: 1px solid @colorGrey3_5;
}

.AdminConsoleSection__Table__Content {
  display: flex;
  padding-top: @spaceNorm;
  padding-bottom: @spaceNorm2;
}

.AdminConsoleSection__Table__Content--Single {
  gap: @spaceNorm2;
  padding-left: @spaceNorm2;
  padding-right: @spaceNorm2;
  flex-direction: row;
  align-items: flex-start;
}

.AdminConsoleSection__Table__Content--Rows {
  flex-direction: column;
  gap: @spaceNorm1_5;

  > * {
    display: flex;
    align-items: flex-start;
    gap: @spaceNorm2;
    padding: 0 @spaceNorm2;
  }
}

.AdminConsoleSection__Tba {
  position: relative;

  &::after {
    content: 'TBA';
    margin-bottom: 2px;
    border-radius: @borderRadiusXxs;
    background-color: @colorGreyBluish2;
    .flex-center();
    width: 30px;
    height: 16px;
    font-size: @font-size-sm-plus;
    font-family: @fontCondensed;
    font-weight: bold;
    color: @colorGreyDark1_3;
    line-height: 16px;
  }
}
.AdminConsoleSection__Tba--Top {
  &::after {
    position: absolute;
    bottom: 100%;
    right: @spaceNorm0_5;
  }
}
.AdminConsoleSection__Tba--Right {
  &::after {
    position: absolute;
    left: 100%;
    margin-left: @spaceNorm;
    top: 50%;
    transform: translateY(-50%);
  }
}

.AdminConsoleSection__FieldPlaceholder {
  background-color: @colorGreyDark2_6;
  border-radius: @borderRadiusXs;
  height: @input-height-md;
  width: 100%;
}

.AdminConsole__ChoiceSelect.ant-radio-group.CheckableGroup--Vertical {
  .ant-radio-wrapper {
    &,
    &.ant-radio-wrapper-disabled {
      height: @input-height-md;
      border-radius: @borderRadius20;
      border: 1px solid @colorGrey3_5;
      background-color: @colorGreyDark5;
      color: @colorGreyBluish3;
      padding: 0 @spaceNorm;
      font-size: @font-size-lg;

      .ant-radio-inner {
        background-color: @colorGreyDark5;
        border-color: @colorGreyBluish3;
        border-width: 2px;
        width: 20px;
        height: 20px;

        &::after {
          margin-top: 1px;
          margin-left: 1px;
        }
      }
    }
    &:hover:not(.ant-radio-wrapper-disabled):not(.ant-radio-wrapper-checked) {
      border-color: @colorOrange1;
      color: @colorOrange1;
      .ant-radio-inner {
        border-color: @colorOrange1;
      }
    }
    &.ant-radio-wrapper-disabled {
      opacity: 0.35;
      &,
      & > *,
      & > * > * {
        cursor: initial;
      }
      .ant-radio-disabled + span {
        color: inherit;
      }
    }
  }

  .ant-radio-wrapper.ant-radio-wrapper-checked {
    &,
    &.ant-radio-wrapper-disabled {
      border: 1px solid @colorGrey3_5;
      background-color: @colorOrange1;
      color: @colorWhite;

      .ant-radio-inner {
        background-color: @colorOrange1;
        border-color: @colorWhite;
        &::after {
          background-color: @colorWhite;
        }
      }
    }
    &.ant-radio-wrapper-disabled {
      opacity: 1;
    }
  }
}

.AdminConsoleContent__Header {
  display: flex;
  align-items: center;
  height: 30px;
  margin: @spaceSm 0 -@spaceNorm0_5 0;

  > .ant-row {
    flex: 1;
  }
}

.AdminConsoleContent__Header--Editing {
  background-color: @colorOrange1;
  border-radius: @borderRadiusXs;
  padding-left: @spaceNorm;
  padding-right: @spaceNorm;

  .AdminConsoleTitle.ant-typography {
    color: @colorWhite;
    text-transform: none;
  }
}
